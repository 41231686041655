import SquareProfile from "./square-profile.jpg";
import FlywheelLogoBanner from "./flywheel-logo-full.png";
import FlywheelLogoBannerBlue from "./flywheel-logo-full-blue.png";
import SwbcLogo from "./swbc-logo.png";
import EpicLogo from "./epic-logo.png";

export const Images: { [key: string]: string } = {
    SquareProfile: SquareProfile,
    FlywheelLogoBanner: FlywheelLogoBanner,
    FlywheelLogoBannerBlue: FlywheelLogoBannerBlue,
    SwbcLogo: SwbcLogo,
    EpicLogo: EpicLogo,
};
